import React, { ChangeEvent } from "react";
import { Theme, useTheme } from "@mui/material";
import { Breakpoint } from "@mui/material";
import { detectIE, detectSafari, useIsMobile } from "./../hooks/hooks";
import { basketExtrasOptionsArrayProp } from "../utils/common-types";
import { ExtraOptionsProps } from "./SemanticTheme/ExtraCard";
import { DropdownOption } from "./SemanticTheme/FHDropdown";
import {
  BrowserDataInput,
  ConfirmedBookingInfo,
  ErrorMessage,
  ReservationExtra,
} from "@generated/types";
import { Nullable } from "../utils";
import moment from "moment";
import { load } from "cheerio";
import { BookingDateRange } from "src/interfaces/bookingForm";
import {
  AuthorProfile,
  BlogPostPage,
} from "src/graphql/generated-strapi/types";

export type maxImgWidth = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
};

export const defaultMaxWidths = {
  xs: 600,
  sm: 960,
  md: 1280,
  lg: 1920,
  xl: 2400,
};

export const defaultMapPopupImageWidths = {
  xs: 90,
  sm: 90,
  md: 90,
  lg: 90,
  xl: 90,
};

export const defaultWhichLogoMaxWidths = {
  xs: 115,
  sm: 115,
  md: 115,
  lg: 115,
  xl: 115,
};
export const getWhichLogo = () => {
  const whichLogoUrl =
    "https://assets.forestholidays.co.uk/damprodblob/assets/uk_holiday_parks_which_logo_464d44c298.png";
  const responsiveWhichLogo = useGenerateResponsiveImageUrl(
    whichLogoUrl,
    defaultWhichLogoMaxWidths,
    undefined,
    true,
  );
  return responsiveWhichLogo;
};

export const onlyRestrictToNumber = (event: React.KeyboardEvent) => {
  if (!/[0-9]/.test(event.key)) event.preventDefault();
};

export const handleWhiteSpacePrefix = (
  e: React.KeyboardEvent<HTMLInputElement>,
) => {
  const inputTarget = e.target as HTMLInputElement;
  if (e.key === " " && inputTarget.value.length === 0) {
    e.preventDefault();
  }
};

export const stripPTagsFromString = (string: string) =>
  string.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, "");

export const matchMedia = () => {
  const theme: Theme = useTheme();

  const breakPointValues = theme.breakpoints.values;
  if (typeof window !== "undefined") {
    const xs = window.matchMedia(`(min-width: ${breakPointValues["xs"]}px)`);
    const sm = window.matchMedia(`(min-width: ${breakPointValues["sm"]}px)`);
    const md = window.matchMedia(`(min-width: ${breakPointValues["md"]}px)`);
    const lg = window.matchMedia(`(min-width: ${breakPointValues["lg"]}px)`);
    switch (true) {
      case lg.matches:
        return "lg";
      case md.matches:
        return "md";
      case sm.matches:
        return "sm";
      case xs.matches:
        return "xs";
      default:
        return "xs";
    }
  } else {
    return "xs";
  }
};

//this function is to get current breakpoint from the browser.
export const useWidth = (): Breakpoint => {
  return isBrowser() ? matchMedia() : "xl";
};

export const isUrl = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch (error) {
    return false;
  }
};

export const generateResponsiveImgUrl = (
  url: string,
  size: Breakpoint,
  maxWidth: maxImgWidth = { xs: 600, sm: 960, md: 1280, lg: 1920, xl: 2400 },
  quality: number = 85,
) => {
  if (!url) return;

  if (isUrl(url)) {
    const imgUrl: URL = new URL(url);
    imgUrl.searchParams.set("width", maxWidth[size].toString());
    imgUrl.searchParams.set("fit", "contain");
    imgUrl.searchParams.set("format", "auto");
    imgUrl.searchParams.set("quality", `${quality}`); // Lossy processing

    return imgUrl.href;
  } else {
    return `${url}?width=${maxWidth[size]}&quality=${quality}`;
  }
};

export const generateResponsiveImgUrlFromCloudflare = (
  url: string,
  size: Breakpoint,
  maxWidth: maxImgWidth = { xs: 600, sm: 960, md: 1280, lg: 1920, xl: 2400 },
  imgQuality: number = 85,
) => {
  if (!url) return;

  const isIE = detectIE();
  const isSafari = detectSafari();
  // const format = isIE || isSafari ? "jpg" : "webp";

  const params = [`width=${maxWidth[size]}`];
  params.push(`quality=${imgQuality}`);
  params.push("fit=contain");
  params.push("format=auto");
  const paramsString = params.join(",");

  return `${process.env.NEXT_PUBLIC_CLOUDFLARE_IMAGES_URL}/${paramsString}/${url}`;
};

export const useGenerateResponsiveImageUrl = (
  url: string | undefined,
  maxWidths?: maxImgWidth,
  imgQuality?: number,
  renderWithNextImageTag = false,
) => {
  if (!!url) {
    const quality = !!imgQuality ? imgQuality : undefined;
    const width = useWidth();
    // for backward compatibility.
    // will be removed once every background image is converted to next/image tag.
    return renderWithNextImageTag
      ? generateResponsiveImgUrl(url, width, maxWidths, quality)
      : generateResponsiveImgUrlFromCloudflare(url, width, maxWidths, quality);
  }
};

export const paymentCardNumberFormate = (
  event: ChangeEvent<HTMLInputElement>,
) => {
  const cardNumber = event.target.value;
  return cardNumber
    .replace(/(\D|\s)+/g, "")
    .substring(0, 22)
    .replace(/\d{4}(?!$)/g, (x) => `${x} `)
    .trim();
};

export enum PaymentDataStatus {
  THREE_D_AUTH = "3DAUTH",
  OK = "OK",
  GVOK = "GVOK",
  SCOK = "SCOK",
  GVSCOK = "GVSCOK",
}

export function isAWhitelistedBot() {
  if (
    typeof window !== "undefined" &&
    typeof window.navigator !== "undefined"
  ) {
    return (
      navigator.userAgent === "ReactSnap" ||
      navigator.userAgent === "fhbot" ||
      navigator.userAgent.includes("Prerender")
    );
  }
}

export const getExistedExtraOptionQty = (
  basketExtrasOptionsList: basketExtrasOptionsArrayProp[],
  extraOptionId: string,
  dateSelected: string,
  timeOptions: string,
) => {
  if (basketExtrasOptionsList && basketExtrasOptionsList?.length > 0) {
    const matchedExtra = basketExtrasOptionsList.find(
      (extraOption) =>
        extraOption?.id === extraOptionId &&
        (dateSelected !== "" ? dateSelected === extraOption.date : true) &&
        (timeOptions !== "" ? timeOptions === extraOption.aMPM : true),
    );
    return matchedExtra && matchedExtra.quantity ? matchedExtra.quantity : 0;
  } else {
    return 0;
  }
};

export const createSelectedExtraOptionsWithDates = (
  updatedState: ExtraOptionsProps[],
  selectedDateDropdownValue: string[],
  selectedTimeDropdownValue: string[],
) => {
  if (updatedState) {
    const selectedExtraOptions = updatedState
      .map((item) => ({
        calendarDate: selectedDateDropdownValue
          ? selectedDateDropdownValue[0]
          : undefined,
        extraOptionId: item.extraId,
        selectedQuantity: item.quantity,
        timeSlot:
          selectedTimeDropdownValue?.length > 0
            ? selectedTimeDropdownValue[0]
            : undefined,
      }))
      .filter(
        (extraOption) =>
          extraOption.selectedQuantity && extraOption.selectedQuantity > 0,
      );
    return selectedExtraOptions;
  } else return [];
};

export const resetQuantity = (
  extraInputData: ExtraOptionsProps[] | undefined,
  extraOptions: ExtraOptionsProps[] | undefined,
) =>
  extraInputData?.map((item) => {
    return {
      ...item,
      quantity: extraOptions?.length === 1 && item.maxQuantity === 1 ? 1 : 0,
    };
  });

export const sortDate = (updatedDates: DropdownOption[]) => {
  return updatedDates?.sort((date1, date2) =>
    date1.value > date2.value ? 1 : date2.value > date1.value ? -1 : 0,
  );
};

export const getExtraStockProps = (
  updatedState: ExtraOptionsProps[],
  extraId: string,
  extraOptionsIds: string[] | undefined,
  cabinReservationId: string,
  selectedDateDropdownValue: string[],
  selectedTimeDropdownValue: string[],
) => ({
  extraId: extraId || "",
  extraOptionIds: extraOptionsIds,
  cabinReservationId: cabinReservationId || "",
  selectedExtraOptions: createSelectedExtraOptionsWithDates(
    updatedState,
    selectedDateDropdownValue,
    selectedTimeDropdownValue,
  ),
});

// This function is to check the featured extra option(item) is max reached or not.
export const isItemMaxReached = (
  extra: ExtraOptionsProps,
  basketExtras?: ReservationExtra[],
  selectedDate?: string,
  selectedTime?: string,
) => {
  let isItemMaxReached = false;
  if (extra && basketExtras && basketExtras.length > 0) {
    basketExtras?.forEach((basketExtra) => {
      basketExtra?.reservationExtraOptions?.forEach(
        (reservationExtraOption: any) => {
          if (reservationExtraOption?.extraOptionId === extra?.extraId) {
            if (extra?.availableDates && extra?.availableDates?.length > 0) {
              extra?.availableDates.forEach((availableDate) => {
                if (
                  availableDate?.calendarDate === basketExtra?.calendarDate &&
                  basketExtra?.calendarDate === selectedDate
                ) {
                  if (
                    availableDate?.availableDatesAndTimes &&
                    availableDate?.availableDatesAndTimes.length > 0
                  ) {
                    availableDate.availableDatesAndTimes.forEach(
                      (availableDatesAndTime) => {
                        if (
                          availableDatesAndTime?.timeSlot ===
                            basketExtra?.aMPM &&
                          basketExtra?.aMPM === selectedTime
                        ) {
                          isItemMaxReached =
                            reservationExtraOption?.itemMaxReached;
                        }
                      },
                    );
                  } else {
                    isItemMaxReached = reservationExtraOption?.itemMaxReached;
                  }
                } else if (
                  availableDate?.availableDatesAndTimes?.length === 0
                ) {
                  isItemMaxReached = reservationExtraOption?.itemMaxReached;
                }
              });
            } else if (extra?.availableDates?.length === 0) {
              isItemMaxReached = reservationExtraOption?.itemMaxReached;
            }
          }
        },
      );
    });
  }
  return isItemMaxReached;
};

// This function will return card expiry date in the format of MM / YY
export const formattedExpiryDate = (
  expiryDate: string,
  isUtcDate: boolean = false,
) => {
  if (isUtcDate) {
    return `${expiryDate.substring(5, 7)} / ${expiryDate.substring(2, 4)}`;
  }

  const targetValue = expiryDate.replace(/(\D|\s)+/g, "");
  const formatedExpiryDate =
    targetValue.length > 4
      ? expiryDate.replace(/(\D|\s)+/g, "").replace(targetValue.slice(2, 4), "")
      : targetValue;

  const expiryDateMMYY = formatedExpiryDate
    .substring(0, 4)
    .replace(/[0-9]{3}/, (x) => `${x.substring(0, 2)} / ${x.slice(-1)}`);

  return expiryDateMMYY;
};

export const isNum = (num: any) => !isNaN(num) && typeof num === "number";

export function getErrorMessageByKey(
  key: string,
  errorMessages: Nullable<ErrorMessage[]>,
) {
  return (errorMessages || []).find((err) => err.key === key);
}

export const isBrowser = () => typeof window !== "undefined";

export const getParsedCachedItem = (key: string) => {
  if (isBrowser()) {
    const cachedItem = localStorage.getItem(key);
    return cachedItem === null ? null : JSON.parse(cachedItem);
  }
};

export const cacheForHours = (key: string, data: unknown, hours: number) => {
  const date = new Date();
  date.setHours(date.getHours() + hours);
  const jsonObject = JSON.stringify({ data, timestamp: date.getTime() });
  if (isBrowser()) localStorage.setItem(key, jsonObject);
};

export const makeHtml = (html: string) => ({ __html: html as string });
export const formatPrice = (num: number, currencySymbol = "£"): string => {
  const value = formatPriceString(num?.toString());
  return `${currencySymbol}${value}`;
};
export const getResponsiveBannerImageUrl = (
  bannerImage: string | undefined,
  mobileBannerImage: string | undefined,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useIsMobile()
    ? mobileBannerImage
      ? mobileBannerImage
      : bannerImage
    : bannerImage
      ? bannerImage
      : undefined;
};

export const useGenerateBannerImage = (
  url: string,
  maxWidth?: maxImgWidth,
  imgQuality?: number,
  renderWithNextImageTag = false,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const width = useWidth();
  const quality = !!imgQuality ? imgQuality : undefined;

  return renderWithNextImageTag
    ? generateResponsiveImgUrl(url, width, maxWidth, quality)
    : generateResponsiveImgUrlFromCloudflare(url, width, maxWidth, quality);
};

export const defaultTileWidths = {
  xs: 400,
  sm: 400,
  md: 400,
  lg: 400,
  xl: 400,
};
export const getBSLPaymentIPUrl = () => {
  let bslUrl =
    typeof $cmsSettings !== "undefined"
      ? $cmsSettings?.graphQLEndpointUrl
      : process.env.NEXT_PUBLIC_BSL_ENDPOINT || "";

  // if (!!bslUrl && bslUrl.endsWith('/graphql')) {
  //   bslUrl = bslUrl.slice(0, -8);
  // }
  if (
    !!bslUrl &&
    (bslUrl.endsWith("/graphql") || bslUrl.endsWith("/graphql/"))
  ) {
    if (bslUrl.endsWith("/graphql")) bslUrl = bslUrl.slice(0, -8);
    if (bslUrl.endsWith("/graphql/")) bslUrl = bslUrl.slice(0, -9);
  }

  return `${bslUrl}/payment/get-ip`;
};
export const getBrowserDataForPaymentByCard = (
  clientIPAddress: string,
): BrowserDataInput => {
  //Get Time Zone Offset
  const date = new Date();
  const timeZoneOffset = date.getTimezoneOffset();

  const browserData: BrowserDataInput = {
    browserAcceptHeader: "*/*",
    browserScreenWidth: window.innerWidth,
    browserScreenHeight: window.innerHeight,
    browserTZ: timeZoneOffset.toString(),
    browserLanguage: navigator.language,
    browserUserAgent: navigator.userAgent,
    challengeWindowSize: "04",
    browserColorDepth: window.screen.colorDepth,
    browserJavascriptEnabled: true,
    clientIPAddress: clientIPAddress,
  };

  return browserData;
};

export function getIsBookingHappeningNow(dateRange: BookingDateRange) {
  if (!!dateRange?.startDate && !!dateRange?.endDate) {
    const { startDate, endDate } = dateRange;
    const currentTzOffsetInHours = getCurrentTimezoneOffsetInHours();
    const resStartDate = moment(moment(startDate))
      .subtract(currentTzOffsetInHours, "h")
      .subtract(1, "d")
      .endOf("day");
    // mid night on the day before the start date
    const dayAfterResEndDate = moment(moment(endDate)).add(
      24 - currentTzOffsetInHours,
      "h",
    );
    const secondsSinceStartDate = moment().diff(resStartDate, "s");
    const secondsUntilEndDate = dayAfterResEndDate.diff(moment(), "s");

    return secondsSinceStartDate >= 0 && secondsUntilEndDate >= 0; // the current date is on or after the reservation start date AND is on or before the reservation end date
  }
  return false;
}

export const isPastBooking = (confirmedBookingInfo: ConfirmedBookingInfo) => {
  const { endDate } = confirmedBookingInfo;

  const currentDate = new Date().setHours(0, 0, 0, 0);

  const isEndDateIsPast =
    new Date(moment(endDate).format("YYYY-MM-DD")).setHours(0, 0, 0, 0) <
    currentDate;

  return isEndDateIsPast;
};

export const isPastDate = (date: string) => {
  const currentDate = new Date().setHours(0, 0, 0, 0);

  const isPastDate =
    new Date(moment(date).format("YYYY-MM-DD")).setHours(0, 0, 0, 0) <
    currentDate;

  return isPastDate;
};

export const getCurrentTimezoneOffsetInHours = () => {
  return moment().utcOffset() / 60; // daylight savings offset in hours
};

export const isElementVisible = (el: HTMLDivElement | null) => {
  if (!el) {
    return false;
  }

  const rect = el?.getBoundingClientRect(); // Optional chaining to avoid errors
  if (!rect) {
    return false;
  }

  const vHeight = window.innerHeight || document.documentElement.clientHeight;

  const efp = (x: number, y: number) => {
    return document.elementFromPoint(x, y);
  };

  // Return false if it's not in the viewport
  if (rect.top > vHeight) {
    return false;
  }

  return el.contains(efp(rect.left, rect.top));
};

export const referrer = isBrowser() ? document.referrer : "";

export const addLazyLoadingToImagesInRichText = (richText: string): string => {
  const imageRegex = /<img\b([^>]*)>/g;
  const LazyLoadingToImagesInRichText =
    richText &&
    richText.replace(imageRegex, (match, imgTag) => {
      if (!imgTag.includes("loading")) {
        imgTag += ' loading="lazy"';
      }
      return `<img${imgTag}>`;
    });

  return LazyLoadingToImagesInRichText;
};

export const updateAbsoluteLink = (link: string): string => {
  const baseURL = process.env.NEXT_PUBLIC_BASE_URL?.endsWith("/")
    ? process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/$/, "")
    : process.env.NEXT_PUBLIC_BASE_URL;

  if (!!link) {
    if (link?.startsWith(`${baseURL}`)) {
      return link;
    } else {
      return `${baseURL}${link}`;
    }
  } else {
    return link;
  }
};

export const updateToAbsoluteURL = (richText: string): string => {
  const $ = load("<html></html>");
  if (richText) {
    const tempElement = $("<div>").html(richText);
    const anchorElements = tempElement.find("a");
    const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || "";

    anchorElements.each((_index, element) => {
      const currentHref = $(element).attr("href");
      if (
        currentHref &&
        currentHref.startsWith("/") &&
        currentHref.endsWith("/")
      ) {
        const href = currentHref.replace(/^\//, "");
        $(element).attr("href", `${baseUrl}${href}`);
      }
    });

    return tempElement.html();
  }
};

export const processRichText = (richText: string) => {
  // Lazy loading images
  const lazyLoadedText = addLazyLoadingToImagesInRichText(richText);
  // Update absolute URLs
  const absoluteURLText = updateToAbsoluteURL(lazyLoadedText);
  return absoluteURLText;
};

export const useDebounce = (func, delay) => {
  let timeout = null;

  return (...args) => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

// Returns a JSON string with keys containing null values removed.
export const removeNullValues = (obj: Record<string, any>): string => {
  return JSON.stringify(obj, (key, value) =>
    value === null ? undefined : value,
  );
};

export const generateStructuredDataForBlogPostPage = (
  blogPage: BlogPostPage,
) => {
  const fhLogoUrl =
    blogPage?.HeaderRef?.data?.attributes?.structuredDataLogo?.data?.attributes
      ?.url;
  const blogCategory = blogPage?.BlogCategory?.data?.attributes?.Name;
  const bannerImgURL = blogPage?.BannerImage?.data?.attributes?.url;
  const authorDetails = blogPage?.AuthorProfileRef?.data?.attributes;
  const authorName = authorDetails?.AuthorFullName;
  const socialLinks = blogPage?.SocialMediaReferences?.data?.map(
    (socialMediaRef) => socialMediaRef?.attributes?.Url,
  );
  const significantLinks = blogPage?.SignificantLinks?.map(
    (link) => link?.repeatableText,
  );
  const authorSignificantLinks = authorDetails?.SignificantLinks?.map(
    (link) => link?.repeatableText,
  );
  const pageURL = `${process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/$/, "")}${
    blogPage?.PageRoute
  }`;

  const publishAtDate =
    blogPage?.publishedAt &&
    moment(blogPage?.publishedAt).format("DD/MM/YYYY") === "18/03/2023"
      ? null
      : blogPage?.publishedAt;
  const createdAtDate =
    blogPage?.createdAt &&
    moment(blogPage?.createdAt).format("DD/MM/YYYY") === "18/03/2023"
      ? null
      : blogPage?.createdAt;

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "BlogPosting",
    headline: blogPage?.BannerTitle,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": pageURL,
      significantLink: significantLinks?.length > 0 ? significantLinks : null,
    },
    image: {
      "@type": "ImageObject",
      url: bannerImgURL,
    },
    author: {
      "@type": authorDetails?.AuthorType ?? null,
      name: authorName,
      sameAs:
        authorSignificantLinks?.length > 0 ? authorSignificantLinks : null,
    },
    publisher: {
      "@type": "Organization",
      name: "Forest Holidays",
      logo: {
        "@type": "ImageObject",
        url: fhLogoUrl,
      },
    },
    url: pageURL,
    datePublished: !!publishAtDate
      ? moment(publishAtDate).format("YYYY-MM-DDTHH:mm:ssZ")
      : null,
    dateModified: blogPage?.updatedAt ?? null,
    keywords: blogPage?.seo?.keywords ?? null,
    genre: blogCategory ?? null,
    dateCreated: !!createdAtDate
      ? moment(createdAtDate).format("YYYY-MM-DDTHH:mm:ssZ")
      : null,
    description: blogPage?.seo?.metaDescription ?? null,
  };

  const cleanedStructuredData = removeNullValues(structuredData);
  return cleanedStructuredData;
};

export const formatPriceString = (priceString: string): string => {
  // Convert the string to a number
  let number = priceString ? parseFloat(priceString) : 0;

  // Determine the formatting options
  const options = {
    minimumFractionDigits: number % 1 === 0 ? 0 : 2,
    maximumFractionDigits: 2,
  };

  // Format the number with commas as thousands separators for UK locale
  return number?.toLocaleString("en-GB", options);
};

export const formatPricesInRichtext = (str: string) => {
  // Regex to find the prices in the string
  return str?.replace(/£(\d+(?:\.\d+)?)/g, (match, price) => {
    // Format each price found
    return `£${formatPriceString(price)}`;
  });
};

// Function to detect if the device is iOS
export const isIOS = () => {
  if (typeof navigator !== "undefined") {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }
  return false;
};
